<template>
  <v-container>
    <v-row justify="space-around">
      <v-card width="400">
        <v-card-title class="mt-8">
          <p class="ml-3">ご注文詳細</p>
        </v-card-title>
        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">商品のご手配状況</div>
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="message in messages"
              :key="message.time"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> {{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    messages: [
      {
        from: "決済",
        message: `ネットでの決済が完了しました`,
        time: "08:12pm 2022/08/09",
        color: "deep-purple lighten-1",
      },
      {
        from: "発送作業",
        message: "現在準備中です。",
        time: "08:37pm 2022/08/09",
        color: "orange",
      },
      {
        from: "---",
        message: "",
        time: "",
        color: "gray lighten-4",
      },
      {
        from: "---",
        message: "",
        time: "",
        color: "gray lighten-4",
      },
      {
        from: "---",
        message: "",
        time: "",
        color: "gray lighten-4",
      },
    ],
  }),
};
</script>
