<template>
  <v-sheet class="mx-auto my-12" max-width="95%" v-if="user">
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      {{$t("priscription.お客様の情報を確認中です。<br />処理が完了するまでお待ちください。")}}
    </div>
    <div v-if="loading === false">
      <p class="ma-5">
        {{ $t("お手続きが完了しました。ご注文内容は下記の通りとなります。") }}
      </p>
      <v-divider></v-divider>
      <v-card>
        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            {{ $t("商品のご手配状況") }}
          </div>
          <v-timeline align-top dense reverse class="ml-10">
            <v-timeline-item
              v-for="message in messages"
              :key="message.from"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> {{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-card-actions>
          <v-btn
            outlined
            color="indigo"
            elevation="2"
            large
            v-on:click="select_clinic()"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("処方箋一覧へ戻る") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <p class="ma-5">{{ $t("ご注文内容") }}</p>
      <v-card>
        <div v-if="order.r_product_name !== ''">
          <p>
            <v-icon color="blue" class="ma-2">mdi-eye</v-icon>{{ $t("右眼") }}
          </p>
          <v-simple-table>
            <tbody>
              <tr v-if="order.r_product_name !== null">
                <td>{{ $t("製品") }}</td>
                <td>{{ order.r_product_name }}</td>
              </tr>
              <tr
                v-if="
                  order.right_count !== null && 0 !== order.right_count.length
                "
              >
                <td>{{ $t("注文数") }}</td>
                <td v-if="order.is_firstcoupon">
                  {{ order.r_price_sale.toLocaleString() }}{{ $t("円") }} ×
                  {{ order.right_count }}{{ $t("箱") }}
                </td>
                <td v-if="!order.is_firstcoupon">
                  {{ order.r_price.toLocaleString() }}{{ $t("円") }} ×
                  {{ order.right_count }}{{ $t("箱") }}
                </td>
              </tr>
              <tr
                v-if="
                  order.right_count !== null && 0 !== order.right_count.length
                "
              >
                <td>{{ $t("金額（税込）") }}</td>
                <td v-if="order.is_firstcoupon">
                  {{ (order.r_price_sale * order.right_count).toLocaleString()
                  }}{{ $t("円") }}
                </td>
                <td v-if="!order.is_firstcoupon">
                  {{ (order.r_price * order.right_count).toLocaleString()
                  }}{{ $t("円") }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="order.l_product_name !== ''">
          <p>
            <v-icon color="blue" class="ma-2">mdi-eye</v-icon>{{ $t("左眼") }}
          </p>
          <v-simple-table>
            <tbody>
              <tr v-if="order.l_product_name !== null">
                <td>{{ $t("製品") }}</td>
                <td>{{ order.l_product_name }}</td>
              </tr>
              <tr
                v-if="
                  order.left_count !== null && 0 !== order.left_count.length
                "
              >
                <td>{{ $t("注文数") }}</td>

                <td v-if="order.is_firstcoupon">
                  {{ order.l_price_sale.toLocaleString() }}{{ $t("円") }} ×
                  {{ order.left_count }}{{ $t("箱") }}
                </td>
                <td v-if="!order.is_firstcoupon">
                  {{ order.l_price.toLocaleString() }}{{ $t("円") }} ×
                  {{ order.left_count }}{{ $t("箱") }}
                </td>
              </tr>
              <tr
                v-if="
                  order.left_count !== null && 0 !== order.left_count.length
                "
              >
                <td>{{ $t("金額（税込）") }}</td>
                <td v-if="order.is_firstcoupon">
                  {{ (order.l_price_sale * order.left_count).toLocaleString()
                  }}{{ $t("円") }}
                </td>
                <td v-if="!order.is_firstcoupon">
                  {{ (order.l_price * order.left_count).toLocaleString()
                  }}{{ $t("円") }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("送料") }}</td>
                <td>{{ this.delivery_fee }}</td>
              </tr>
              <tr>
                <td>{{ $t("合計金額（税込）") }}</td>
                <td>{{ order.toral_price.toLocaleString() }}{{ $t("円") }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-chip
            v-if="this.order.is_coupon"
            class="ma-2"
            color="pink"
            large
            label
            text-color="white"
          >
            <v-icon left> mdi-label </v-icon>
            {{ this.order.coupon.value }}{{ this.order.coupon.type
            }}{{ $t("割引クーポン適用") }}
            <span v-if="order.coupon.type == '%'"
              >{{ this.order.discount }}{{ $t("円") }}{{ $t("割引中") }}</span
            >
          </v-chip>
        </div>
      </v-card>

      <p class="ma-5">{{ $t("お送り先") }}</p>
      <v-card>
        <v-simple-table>
          <tbody>
            <tr>
              <td>{{ $t("お宛名") }}</td>
              <td>{{ order.delivery_name }}</td>
            </tr>
            <tr>
              <td>{{ $t("郵便番号") }}</td>
              <td>{{ order.delivery_postcode }}</td>
            </tr>
            <tr>
              <td>{{ $t("ご住所") }}</td>
              <td>{{ order.delivery_address }}</td>
            </tr>
            <tr>
              <td>{{ $t("メールアドレス") }}</td>
              <td>{{ order.delivery_email }}</td>
            </tr>
            <tr>
              <td>{{ $t("携帯電話") }}</td>
              <td>{{ order.delivery_cellnumber }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <p class="ma-5">
        {{
          $t("お問い合わせの際は、クリニック名、患者様番号をお伝えください。")
        }}
      </p>

      <v-card>
        <v-card-text>
          <div>{{ $t("お問い合わせ") }}</div>
          {{ clinic.saler_name }}
          <p>{{ clinic.saler_call }}</p>
          <p>{{ clinic.saler_email }}</p>
          <p>{{ clinic.saler_holiday }}</p>
          <v-simple-table>
            <tbody>
              <tr v-if="order.clinic_name">
                <td>{{ $t("クリニック名") }}</td>
                <td>{{ order.clinic_name }}</td>
              </tr>
              <tr v-if="order.patientNumber !== null">
                <td>{{ $t("患者様番号") }}</td>
                <td>{{ order.patientNumber }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="indigo"
            elevation="2"
            large
            v-on:click="select_clinic()"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("処方箋一覧へ戻る") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import { Firebase } from "../config/firebase";
import i18n from "@/i18n";
export default {
  name: "paind",
  data: () => ({
    loading: true,
    user: null,
    order: null,
    clinic: null,
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    delivery_fee() {
      if (this.order.delivery_fee == 0) {
        return i18n.tc("無料");
      } else {
        return this.order.delivery_fee.toLocaleString() + i18n.tc("円（税込）");
      }
    },
    messages() {
      return this.order.processes;
    },
  },

  created: async function () {
    this.loading = true;
    await this.fetchUsers();
    await this.fetchOrder();
    await this.fetchClinic();
  },
  methods: {
    select_clinic: function () {
      this.$router.push({
        name: "priscription_list",
        params: { id: this.clinic.id },
      });
    },
    fetchUsers: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
    },
    fetchOrder: async function () {
      const orderRef = await Firebase.firestore()
        .collection("Orders")
        .doc(this.id);
      await orderRef.get().then((doc) => {
        if (doc.exists) {
          this.order = doc.data();
          this.order.id = doc.id;
        } else {
          console.log("No order document!");
        }
        this.error = true;
      });
    },
    fetchClinic: async function () {
      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.order.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });
      this.loading = false;
    },
  },
};
</script>
